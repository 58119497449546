.links {
	margin-top: 2em;
	display: flex;
}

.links > * {
	margin: auto;
}

.logoInline {
	display: inline-block;
	height: 1em;
	width: auto;
	vertical-align: middle;
}
