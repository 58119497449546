.year {
    display: grid;
    grid:
        '....... ......... ......... ........ ........... ....... .......... .........  .....' 1em
        '....... line-left line-left ........ year        ....... line-right line-right .....'
        '....... line-left line-left ........ age         ....... line-right line-right .....'
        '....... ......... ......... ........ ........... ....... .......... .......... .....' 1em
        '....... ......... content   content  content     content content    .......... .....'
        / .625em   0       1fr       1em     auto        1em     1fr         0        .625em
}

.year::before, .year::after {
    border-top: 1px solid currentColor;
    content: "";
    height: 0;
    margin: auto;
    width: 100%;
}

.year::before {
    grid-area: line-left;
}

.year::after {
    grid-area: line-right;
}



.month {
    display: grid;
    grid:

        '........ ....... .....   ....... ......' .2em
        '........ ....... month   ....... ......'
        '........ ....... ....... ....... ......' .2em
        '........ content content content ......'
        '........ ....... ....... ....... ......' .2em
        /1.25em     1fr      auto   1fr     1.25em;
    width: 30em;
}

.monthName {
    grid-area: month;
    font-style: italic;
}

.event {
    max-width: 30em;
    margin-bottom: 1em;
    width: 100%;
}

.event::before {
    margin-left: 3em;
}

.yearIndicator, .ageIndicator {
    margin: auto;
}

.yearIndicator {
    grid-area: year;
}

.ageIndicator {
    grid-area: age;
}

.content {
    grid-area: content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
