.time-eye * {
  stroke: var(--foreground-color, black);
  fill: none;
}

.time-eye [data-part="pupil"] {
  fill: var(--foreground-color, black);
}

.time-eye [data-part="tear"] {
  fill: var(--background-color, white);
}
