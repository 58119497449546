.link {
    font-style: italic;
    color: currentColor;
}

.link:visited {
	text-decoration-color: var(--visited-link-color);
}

.link:link {
	text-decoration-color: var(--link-color);
}
